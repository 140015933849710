import * as React from "react"
import Layout from "@lekoarts/gatsby-theme-minimal-blog/src/components/layout"

const DocsAudit = () => (
  <Layout>
    <h1>Documentation Audit Example</h1>
    <iframe
      title="docs-audit-presentation"
      src="https://docs.google.com/presentation/d/e/2PACX-1vRR_3gStB05dkbspAVr_MxYiYON7B4v-pyDYZJUKBl7wgcEyqs4_XPaEV0lzF52PilxmaIS9lYFw0m_/embed?start=true&loop=false&delayms=3000"
      frameborder="0" width="960" height="569" allowfullscreen="true" mozallowfullscreen="true"
      webkitallowfullscreen="true">
    </iframe>
    <p>The slide deck above is an example of a full documentation audit.</p>
    <p>A documentation audit includes a complete evaluation of API reference material and developer portal information.
      Many recommendations applied to the complete set of documentation,
      such as checking the readability score of long sentences and establishing an internal style guide to maintain consistent terminology.
    </p>
    <p>
      Other recommendations are more specific, such as removing duplicate content in a tutorial.
      Each recommendation includes a screenshot along with a written explanation and suggested solution.
    </p>
    <p>
      Please click through to explore and, as always, <a href="mailto:linda.b.robledo@gmail.com">reach out</a> with any questions!
    </p>
  </Layout>
)

export default DocsAudit
